import React, { useEffect, useState } from "react";
import Games from "../Games.json";
import GameCard from "./GameCard";
import Loader from "./Loader";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);

  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <section className="flex items-center justify-center p-8">
      <div className="grid xl:grid-cols-4 ms:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 w-full">
        {randomGames.map((data, index) => (
          <GameCard key={index} data={data} />
        ))}
      </div>
    </section>
  );
}

import React, { useState } from "react";
import Loader from "./Loader";

export default function TermCondition() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 bg-gradient-to-r from-purple-100 via-orange-100 to-blue-200 rounded-lg shadow m-3">
        <h1 className="text-2xl font-bold text-center">Terms and Conditions</h1>
        <p className="my-3">
          Welcome to <span className="font-bold">booktax.xyz</span>. By
          accessing and using our website, you agree to comply with and be bound
          by the following terms and conditions. Please review them carefully
          before using our services.
        </p>

        <h6 className="font-bold mb-3 mt-6">1. Use of the Website</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Eligibility:</b> You must be at least 18 years old to use this
            website.
          </li>
          <li className="my-2">
            <b>Account Responsibility:</b> You are responsible for maintaining
            the confidentiality of your account information and password.
          </li>
          <li className="my-2">
            <b>Prohibited Activities:</b> You agree not to engage in any
            unlawful activities or use the website in a way that could harm
            booktax.xyz or other users.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">2. Intellectual Property</h6>
        <p className="my-3">
          All content on this website, including text, graphics, logos, and
          software, is the property of booktax.xyz and is protected by
          intellectual property laws. You may not reproduce, distribute, or
          create derivative works from any content without our explicit
          permission.
        </p>

        <h6 className="font-bold mb-3 mt-6">3. Limitation of Liability</h6>
        <p className="my-3">
          booktax.xyz is not liable for any damages arising from the use of our
          website or services. We provide the website "as is" without any
          warranties of any kind.
        </p>

        <h6 className="font-bold mb-3 mt-6">4. Changes to Terms</h6>
        <p className="my-3">
          We reserve the right to modify these terms and conditions at any time.
          Changes will be effective immediately upon posting on the website.
          Your continued use of the website constitutes your acceptance of the
          new terms.
        </p>

        <h6 className="font-bold mb-3 mt-6">5. Contact Us</h6>
        <p className="my-3">
          If you have any questions about these terms, please contact us at
          support@booktax.xyz.
        </p>
      </section>
    </>
  );
}

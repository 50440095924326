import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    // <div className="rounded-lg overflow-hidden shadow-lg shadow-gray-500 transform transition duration-300 hover:scale-90 ">
    //   <img className="w-full" src={data.logo} alt={data.title} />
    //   <div className="flex items-center justify-between px-3 py-2 bg-gradient-to-br from-orange-300 to-orange-500 ">
    //     <span className="text-xl font-bold text-gray-900 ">{data.title}</span>

    //     <div className="button-wrapper my-3">
    //       <Link
    //         to={`/single?slug=${data.slug}`}
    //         className="relative text-orange-200 bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-xl py-2 mx-auto text-center me-2 mb-2 overflow-hidden p-4"
    //       >
    //         <span className="relative z-10">Play</span>
    //         {/* <span className="absolute inset-0 border-4 border-white border-opacity-30 rounded-full animate-spin-slow "></span> */}
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="relative flex justify-center items-center rounded-lg ">
      <Link
        to={`/single?slug=${data.slug}`}
        className="relative shadow-lg flex justify-center items-center transition-transform duration-500 rounded-lg  backdrop-blur-md"
      >
        <img className="w-full rounded-2xl" src={data.logo} alt={data.title} />
        <span className="absolute bottom-0 rounded-2xl w-full h-10 bg-white/60 flex justify-center items-center text-white">
          {data.title}
        </span>
      </Link>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="shadow-[5px_5px_15px_#bebebe,-5px_-2px_5px_#ffffff] text-white p-2 my-1 rounded-lg text-center mx-3">
      <p>
        &copy; {currentYear}
        <span>
          <Link to="/" className="hover:underline ms-2">
            booktax.xyz
          </Link>
        </span>
      </p>
    </footer>
  );
}

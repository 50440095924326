import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 bg-gradient-to-r from-purple-100 via-orange-100 to-blue-200 rounded-lg shadow m-3">
        <h1 className="text-2xl font-bold text-center">About Us</h1>
        <p>
          Welcome to <b className="font-bold">booktax.xyz!</b>
        </p>
        <p className="my-3">
          At <b className="font-bold">booktax.xyz</b>, we specialize in
          providing comprehensive and user-friendly tax solutions. Our mission
          is to simplify the tax filing process for individuals and businesses,
          ensuring accuracy and compliance with the latest tax laws.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in 2024, booktax.xyz was created with the vision of making tax
          filing accessible to everyone. We understand that taxes can be complex
          and stressful, which is why we offer tools and resources designed to
          make the process as straightforward as possible.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team consists of experienced tax professionals, software
          developers, and customer support specialists. We are dedicated to
          providing exceptional service and support to our users.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Tax Filing:</b> Easy-to-use online tax filing for individuals and
            businesses.
          </li>
          <li className="my-2">
            <b>Tax Planning:</b> Tools and resources to help you plan and
            optimize your tax situation.
          </li>
          <li className="my-2">
            <b>Customer Support:</b> Expert assistance available to answer your
            tax-related questions.
          </li>
        </ul>

        <p className="my-2">
          Thank you for choosing booktax.xyz for your tax needs. We look forward
          to serving you!
        </p>
      </section>
    </>
  );
}
